@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700;800&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Poppins', 'sans-serif';
}

.appMain {
  /* width: min-content; */
  margin: auto;
  /* max-width: 1440px; */
}

@font-face {
  font-family: "EE Rocklin";
  src: local("EE Rocklin"),
    url("./fonts/EE-Rocklin/EE-Rocklin.ttf") format("truetype");
  font-weight: bold;
}

.font-face-rocklin {
  font-family: "EE Rocklin";
}

video[poster] {
  object-fit: cover;
}

.vjs-poster {
  background-size: cover;
  background-position: inherit;
}

.vjs-big-play-button {
  width: 100%;
  height: 100%;
  background-image: url('/src/assets/images/play_icon.svg');
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: 46px;
  background-position: 50% calc(50% - 10px);
}

.hero-image {
  background-image: url('/src/assets/webp/mobile_responsive.jpg');
  background-size: cover;
  height: 90vh;
  padding: 20px;
}

.vusport-logo {
  width: 7rem;
}

.vusport-logo-container {
  display: grid;
  place-items: center;
}

.container-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 97%;
  justify-content: space-between;
}

.hero-image-title {
  font-size: 2.2rem;
  color: #fff;
  margin-top: 20px;
  text-transform: uppercase;
  letter-spacing: 0px;
  text-align: center;
}

.hero-image-subtitle {
  font-size: 1.7rem;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0px;
}

.hero-image-download {
  display: grid;
  gap: 1rem;
}

.download-app-text {
  font-size: 1.4rem;
  color: #E8DBFF;
  text-transform: uppercase;
  letter-spacing: 0px;
  /* text-align: center; */
}

.play-store-image {
  /* width: 40vw; */

  width: 10rem;

}

.app-store-image {
  /* width: 40vw; */

  width: 10rem;

}

.feature-container {
  background: rgb(251, 241, 233);
  padding: 18px 0px 0 0;
}

.video-title {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  padding: 0px 0px 5px 0px;
}

.video-subtitle {
  text-align: center;
  font-size: 14px;
  padding: 5px 0px;
  color: rgb(110, 110, 110);
}

.vjs-control-bar {
  background-color: transparent !important;
}


.video {
  border-radius: 20px;
}

.video-container {
  padding: 20px 0px 50px;
  display: flex;
  justify-content: center;
  /* width: 80% !important; */
  /* overflow: hidden; */
  margin: auto;
}

.video-js {
  overflow: hidden;
  border-radius: 15px;
}

@media screen and (max-width: 640px) {
  .video-title {
    font-size: 1.5rem;
  }

  .video-container {
    width: 95%;
  }

  .video-js {
    height: 220px !important;
  }

  .video-subtitle {
    padding: 5px 13px;
  }
}

.mobile-screens {
  display: none;
}

.feature-details-container {
  display: flex;
  flex-direction: column;
}

.feature-details-title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  padding: 5px 0px;
}

.feature-details-subtitle {
  text-align: center;
  font-size: 14px;
  color: rgb(110, 110, 110);
  padding: 5px 0px;
}

.streaming-icon {
  width: 35px;
  height: 35px;
  background: linear-gradient(rgb(232, 87, 32) 0%, rgb(232, 137, 32) 100%) 0% 0% no-repeat padding-box padding-box transparent;
  display: grid;
  place-items: center;
  border-radius: 50%;
  outline-offset: 15px;
  outline: #E88920 solid 4px;
}

.feature-icon {
  width: 35px;
  height: 35px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  outline-offset: 15px;
  outline: #E88920 solid 4px;
}

.section-2 {
  padding-top: 30px;
  display: flex;
  width: 80%;
  justify-content: space-evenly;
  margin: auto;
}

.single-feature-container {
  display: flex;
  padding: 40px 0px;
  gap: 40px;
}

.single-feature-text-container {
  max-width: 345px;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  margin-top: -20px;
}

.single-feature-title {
  font-size: 14px;
  font-weight: bold;
}

.single-feature-subtitle {
  font-size: 0.9rem;
  color: rgb(110, 110, 110);
}

.download-details-icons {
  display: flex;
  justify-content: center;
  padding: 25px 0px;
}

.download-details-text {
  font-size: 22px;
  font-weight: bold;
  padding: 25px 0px 5px;
  text-align: center;
}

.sssg-logo-text {
  font-size: 11px;
  color: rgb(110, 110, 110);
  text-align: center;
  padding: 5px 0px;
}

.sssg-logo {
  width: 100px;
}

.social-icons-container {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.social-icon {
  width: 30px;
}

.copyright-text {
  font-size: 12px;
  color: rgb(157, 159, 161);
  text-align: center;
}

.app-screenshot {
  height: 326px;
}

.download-app-container {
  display: block;
}

.app-screenshot-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  padding: 20px;
  margin-top: -100px;
}

.hero-image-title-2 {
  padding-left: 6px;
}

.download-details-paragraph {
  color: #222020;
  opacity: 0.8;
  font-size: 18px;
  font-weight: 400;
  padding-top: 20px;
}

.download-details-text-web {
  display: none;
}

.download-details-text-mobile {
  display: block;
}

.blue-svg-mobile {
  /* transform: rotate(180deg); */
  /* margin-top: -45px; */
  margin-top: -74px;
  display: block;
}

.blue-svg-web {
  /* transform: rotate(180deg); */
  margin-top: -120px;
  display: none;
}

.orange-svg-mobile {
  /* transform: rotate(180deg); */
  margin-top: -96px;
  display: block;
}

.orange-svg-web {
  /* transform: rotate(180deg); */
  margin-top: -30px;
  display: none;
}

@media screen and (max-width: 900px) {
  .download-app-text {    
    text-align: center;
  }
}
@media screen and (min-width: 900px) {

  .hero-image {
    background-image: url('/src/assets/webp/header_image.jpg');
    height: 810px;
  }

  .vusport-logo-container {
    place-items: start;
  }

  .container-1 {
    display: block;
  }

  .vusport-logo {
    /* width: 10rem; */
    width: 8rem;
    margin-left: 50px;
  }

  .hero-image-title {
    font-size: 8.5rem;
    /* text-align: end; */
    text-align: start;
    margin:auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
  }

  .hero-image-subtitle {
    font-size: 2.7rem;
    /* text-align: end; */
    width: 89%;
    text-align: start;
    margin:auto;
    margin-top: -25px;
  }

  .hero-image-download {
    /* place-items: end; */
    text-align: start;
    margin:auto;
    width: 90%;
    margin-top: 60px;
    gap: 0.5rem;
  }

  .play-store-image {
    /* width: 12vw; */

    width: 10rem;

  }

  .app-store-image {
    /* width: 12vw; */

    width: 10rem;

  }

  .video-title {
    /* height: 490px; */
    /* width: 65%; */
    margin: 0 auto;
    text-align: center;
    max-width: 758px;
    background: #fbf1e9;
    /* padding-top: 50px; */
  }

  .mobile-screens {
    display: block;
    max-width: 525px;
  }

  .feature-details-title {
    text-align: start;
    margin-left: -20px;
    text-align: center;
    font-size: 1.8rem;
    font-weight: 700;
    padding: 5px 0px;
  }

  .feature-details-subtitle {
    text-align: start;
    margin-left: -20px;
  }

  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 60px;
    margin-top: -128px;
  }

  .social-icon {
    width: 50px;
  }

  .app-screenshot {
    height: 595px;
  }

  .download-app-container {
    display: flex;
    flex-direction: row;
    width: 80%;
    margin: auto;
    align-items: center;
  }

  .download-details-text {
    text-align: start;
  }

  .download-details-icons {
    justify-content: start;
  }

  .hero-image-title-2 {
    padding-left: 0px;
    margin-top: -40px;
  }

  .single-feature-subtitle {
    font-size: 1.2rem;
  }

  .download-details-text-web {
    display: block;
  }

  .download-details-text-mobile {
    display: none;
  }

  .blue-svg-mobile {
    display: none;
  }

  .blue-svg-web {
    display: block;
  }

  .orange-svg-mobile {
    display: none;
  }

  .orange-svg-web {
    display: block;
  }

}

.margin-left-10 {
  margin-left: 10px;
}

.py-5 {
  padding: 5px 0px;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

@media screen and (min-width:640px) and (max-width:900px) {
  .blue-svg-mobile {
    transform: rotate(180deg);
    margin-top: -47px;
    display: block;
  }

}